import { FormattedMessage } from 'react-intl'
import { GetStaticProps } from 'next'

import ModuleProductList from 'src/components/Modules/ModuleProductList/ModuleProductList'
import { Flex, Box } from 'src/components/UI/Grid/Grid'
import { GET_CUSTOM_404_PAGE } from 'src/apollo/queries'
import { apolloClient } from 'src/apollo/client'

import styles from './Custom404.module.scss'

import type { Custom404Page } from 'src/apollo/types'

const Custom404 = ({ products }: Custom404Page) => {
  return (
    <Flex flexDirection="column">
      <Flex
        className={styles.custom404}
        textAlign="center"
        flexDirection="column"
        paddingTop={[21, null, null, 24]}
        paddingBottom={[20, null, null, 18]}
      >
        <Box marginBottom={[4]} className={styles.title} as="h1">
          <FormattedMessage
            defaultMessage="Page not found :("
            id="+bdz/f"
            description="Title on not found page"
          />
        </Box>
        <Box as="p" className={styles.text}>
          <FormattedMessage
            defaultMessage="The page you tried to access does not exist."
            id="bpd2Jp"
            description="Text on not found page"
          />
        </Box>
      </Flex>
      {products && products.length > 0 && (
        <Flex className={styles.products}>
          <ModuleProductList title={'Bestsellers'} products={products} />
        </Flex>
      )}
    </Flex>
  )
}

export default Custom404

export const getStaticProps: GetStaticProps = async (args) => {
  const { preview, locale, defaultLocale } = args
  const props: any = {
    products: [],
  }

  const result = await apolloClient(preview ? preview : false).query({
    query: GET_CUSTOM_404_PAGE(
      locale ? locale : defaultLocale ? defaultLocale : ''
    ),
  })

  const products = result.data.custom404Page.products
  props.products = products

  return { props }
}
